import type { Routes } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: 'about',
        loadComponent: () =>
          import('./pages/about/about.component').then((m) => m.AboutComponent),
      },
      {
        path: 'citizens',
        loadComponent: () =>
          import('./pages/citizens/citizens.component').then(
            (m) => m.CitizensComponent
          ),
      },
      {
        path: 'cities',
        loadComponent: () =>
          import('./pages/cities/cities.component').then(
            (m) => m.CitiesComponent
          ),
      },
      {
        path: 'privacy-policy',
        loadComponent: () =>
          import('./pages/privacy-policy/privacy-policy.component').then(
            (m) => m.PrivacyPolicyComponent
          ),
      },
      {
        path: 'frequently-asked-questions',
        loadComponent: () =>
          import(
            './pages/frequently-asked-questions/frequently-asked-questions.component'
          ).then((m) => m.FrequentlyAskedQuestionsComponent),
      },
      {
        path: 'terms-and-conditions',
        loadComponent: () =>
          import(
            './pages/terms-and-conditions/terms-and-conditions.component'
          ).then((m) => m.TermsAndConditionsComponent),
      },
      {
        path: 'businesses',
        loadComponent: () =>
          import('./pages/business/business.component').then(
            (m) => m.BusinessComponent
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
