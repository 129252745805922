import { Component } from '@angular/core';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CookiesComponent } from '../components/cookies/cookies.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterComponent,
    RouterOutlet,
    CookiesComponent,
  ],
  template: `
    <div class="flex flex-col min-h-screen">
      <header class="sticky top-0 z-[100] min-w-[270px]">
        <app-navbar></app-navbar>
      </header>
      <main class="flex-grow">
        <router-outlet></router-outlet>
      </main>
      <app-footer class="min-w-[270px]"></app-footer>
      <app-cookies></app-cookies>
    </div>
  `,
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {}
