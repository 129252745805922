import { Component, LOCALE_ID, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LanguageService } from './shared/services/language.service';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';

import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);
@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    providers: [{ provide: LOCALE_ID, useValue: 'pt-PT' }]
})
export class AppComponent {
  title = 'weAYR';
  platformID = inject(PLATFORM_ID);
  actualRoute = '/';
  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.actualRoute = event.url;
        }
      });

      const savedLanguage = localStorage.getItem('language');
      const defaultLanguage = 'en-US';
      this.languageService.changeLanguage(savedLanguage || defaultLanguage);
    }
  }
}
