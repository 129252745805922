let url = 'https://dev-weayr.ayrplatform.com/';

export const environment = {
  production: false,
  envName: 'DEV',
  url: `${url}`,
  translationBaseUrl: 'https://dev-weayr.ayrplatform.com',
  x_app_version: '2.0.0',
  x_build_number: 'weayr.dev.ceiia.com',
};
